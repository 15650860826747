<template>
	<ele-div>
		<div >
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
		</div>
		<div style="display: flex;margin-top: -20px;" >
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :choose.sync="choose" :key="tableKey" :config="table">
					<template slot="table">
						<el-table-column prop="tran_id" label="交易ID" width="80">
						</el-table-column>
						
						<el-table-column prop="trade_no" label="交易单号" width="140">
						</el-table-column>
						<el-table-column prop="trade_time" label="交易时间" width="170">
						</el-table-column>
						<el-table-column prop="amount" label="金额">
						</el-table-column>
						<el-table-column prop="real_name" label="功德主">
						</el-table-column>
						<el-table-column prop="mobile" label="手机号码">
						</el-table-column>
						<el-table-column prop="content" label="祈祷内容">
						</el-table-column>
						<el-table-column prop="name" label="设备名称">
						</el-table-column>
						<el-table-column prop="address" label="设备地址">
						</el-table-column>
						<el-table-column prop="pay_type_txt" label="支付方式">
						</el-table-column>
						<el-table-column prop="status_txt" label="状态">
							<template slot-scope="scope, index">
								<span  v-if="scope.row.status_txt == '已完成'" style="color: #67c23a;line-height: 33px;">已完成</span>
								<span v-else style="color: #e6a23c;line-height: 33px;">{{scope.row.status_txt}}</span>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.tran.list/",
					method: "post",
					where: {
						trade_no: '',//	是	string	交易单号
						start_date: '',//: '',//	是	string	开始时间
						end_date: '',//	是	string	截止时间
						mobile: '',//	是	string	手机号码
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false ,// 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				tableKey: Math.random(),
				// 表格多选
				choose: [],
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.tableKey = Math.random()
				this.$refs.table.reload()
			},
			download () {
				let data = {...this.table.where}
				delete data.page
				this.$http.request({
					url: '/api.admin.tran.export/',
					method: 'post',
					data: data,
				}).then((res) => {
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
