<template>
	<ele-div style="position: relative;">
		<div style="margin-bottom: 0px;">
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
			<div style="margin-left: 20px;padding-top: 20px;position: absolute;right: 20px;top: 0px;">
				<el-button module="role" width="460" title="添加角色" action="getFilterSearch" v-popup.roleAdd type="primary" icon="el-icon-circle-plus-outline">添加</el-button>
			</div>
		</div>
		<div style="display: flex;margin-top: -20px;" >
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :choose.sync="choose" :config="table">
					<template slot="table">
						<el-table-column prop="role_name" label="角色名称">
						</el-table-column>
						<el-table-column prop="is_used" label="状态">
							<template slot-scope="scope, index">
								<span v-if="scope.row.is_used == 1" style="color: #67c23a;">启用</span>
								<span v-if="scope.row.is_used == 0" style="color: #e6a23c;">暂停</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" fixed="right" min-width="270">
							<template slot-scope="scope, index">
								<div class="btns-rad100">
									<el-button :key="scope.row.idKey" :id="'nodeedit' + scope.row.idKey"  module="role" width="460" title="编辑角色信息" size="small" class="el-button-radio" action="getFilterSearch" v-popup.roleAdd="scope.row" type="text">编辑</el-button>
									<ele-pop-confirm width="424px" @confirm="getFilterSearch" :key="scope.row.role_id"
										:httpData="{'url': '/api.admin.role.del/', 'data': {'role_id': scope.row.role_id}}" isType="deletePost"
										title="角色将被删除且无法撤销，确定删除？">
										<el-button style="margin-left: 6px;color: #FE0000 !important;" class="el-button-radio" type="text"
											size="small">删除</el-button>
									</ele-pop-confirm>
								</div>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.role.list/",
					method: "post",
					where: {
						role_name: '',
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false // 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				// 表格多选
				choose: [],
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.$refs.table.reload()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
