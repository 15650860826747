<template>
	<ele-div style="position: relative;">
		<div style="margin-bottom: 0px;">
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
			<div style="margin-left: 20px;padding-top: 20px;position: absolute;right: 20px;top: 0px;">
				<el-button module="mechanism" width="460" title="添加机构" action="getFilterSearch" v-popup.mechanismAdd type="primary" icon="el-icon-circle-plus-outline">添加</el-button>
			</div>
		</div>
		<div style="display: flex; margin-top: -20px;">
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :key="tableKey" :choose.sync="choose" :config="table">
					<template slot="table">
						<el-table-column prop="corp_name" label="机构名称">
						</el-table-column>
						<el-table-column prop="province" label="地区">
							<template slot-scope="scope, index">
								{{scope.row.province}} {{scope.row.city}} {{scope.row.district}}
							</template>
						</el-table-column>
						<el-table-column prop="address" label="详细地址">
						</el-table-column>
						<el-table-column prop="real_name" label="联系人">
						</el-table-column>
						<el-table-column prop="contact" label="联系方式">
						</el-table-column>
						<el-table-column label="操作" fixed="right" min-width="100">
							<template slot-scope="scope, index">
								<div class="btns-rad100">
									<el-button :key="scope.row.idKey" :id="'mecedit' + scope.row.idKey"  module="mechanism" width="460" title="编辑机构信息" size="small" class="el-button-radio" action="getFilterSearch" v-popup.mechanismAdd="scope.row" type="text">编辑</el-button>
									<ele-pop-confirm width="424px" @confirm="getFilterSearch" :key="scope.row.corp_id"
										:httpData="{'url': '/api.admin.corp.del/', 'data': {'corp_id': scope.row.corp_id}}" isType="deletePost"
										title="机构将被删除且无法撤销，确定删除？">
										<el-button style="margin-left: 6px;color: #FE0000 !important;" class="el-button-radio" type="text"
											size="small">删除</el-button>
									</ele-pop-confirm>
								</div>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.corp.list/",
					method: "post",
					where: {
						corp_name: '',
						real_name: '',
						contact: '',
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false // 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				// 表格多选
				choose: [],
				tableKey: Math.random()
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.tableKey = Math.random()
				this.$refs.table.reload()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
