<template>
	<ele-div style="position: relative;">
		<div style="margin-bottom: 0px;">
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
			<div style="margin-left: 20px;padding-top: 20px;position: absolute;right: 20px;top: 0px;">
				<el-button module="node" width="460" title="添加网点" action="getFilterSearch" v-popup.nodeAdd type="primary" icon="el-icon-circle-plus-outline">添加</el-button>
			</div>
		</div>
		<div style="display: flex; margin-top: -20px;" >
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :choose.sync="choose" :key="tableKey" :config="table">
					<template slot="table">
						<el-table-column prop="node_name" label="网点名称">
						</el-table-column>
						<el-table-column prop="corp_name" label="机构名称">
						</el-table-column>
						<el-table-column prop="province" label="地区" width="300">
							<template slot-scope="scope, index">
								{{scope.row.province}} {{scope.row.city}} {{scope.row.district}}
							</template>
						</el-table-column>
						<el-table-column prop="address" label="详细地址">
						</el-table-column>
						<el-table-column prop="today_amount" label="今日捐款" width="120">
						</el-table-column>
						<el-table-column prop="amount" label="累计捐款" width="120">
						</el-table-column>
						<el-table-column label="操作" fixed="right" min-width="100">
							<template slot-scope="scope, index">
								<div class="btns-rad100">
									<el-button :key="'nodeedit' + scope.row.idKey" :id="'nodeedit' + scope.row.idKey"  module="node" width="460" title="编辑基本资料" size="small" class="el-button-radio" action="getFilterSearch" v-popup.nodeAdd="scope.row" type="text">基本资料</el-button>
									<el-button :key="'node1edit' + scope.row.idKey" :id="'nodee2dit' + scope.row.idKey"  module="node" width="560" title="设置结算账号" size="small" class="el-button-radio" action="getFilterSearch" v-popup.infoEdit="scope.row" type="text">账号</el-button>
									<el-button :key="'node21edit' + scope.row.idKey" :id="'no4dee2dit' + scope.row.idKey"  module="node" width="850" title="编辑网点简介" size="small" class="el-button-radio" action="getFilterSearch" v-popup.desEdit="scope.row" type="text">简介</el-button>
									<el-button :key="'node1edit' + scope.row.idKey" :hide_sure="true" :id="'node1edit' + scope.row.idKey"  module="node" width="1380" title="配置网点图文资料" size="small" class="el-button-radio" action="getFilterSearch" v-popup.detailInfo="scope.row" type="text">配置</el-button>
									<ele-pop-confirm width="424px" @confirm="getFilterSearch" :key="scope.row.node_id"
										:httpData="{'url': '/api.admin.node.del/', 'data': {'node_id': scope.row.node_id}}" isType="deletePost"
										title="网点将被删除且无法撤销，确定删除？">
										<el-button style="margin-left: 6px;color: #FE0000 !important;" class="el-button-radio"  type="text"
											size="small">删除</el-button>
									</ele-pop-confirm>
								</div>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.node.list/",
					method: "post",
					where: {
						node_name: '',
						address: '',
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false // 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				// 表格多选
				choose: [],
				tableKey: Math.random()
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.tableKey = Math.random()
				this.$refs.table.reload()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
