<template>
	<div style="position: relative;">
		<div style="padding: 22px 0 0 20px;">
			<div class="filter-data">
				<div class="filter-data-item">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="版本号">
							<el-input v-model="table.where.version_code" clearable placeholder="输入版本号查询"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" icon="el-icon-search" @click="inputChange">查询</el-button>
							<el-button type="primary" icon="el-icon-refresh" @click="rasetTable">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	export default {
		name: "filter-type",
		props: ['table'],
		data() {
			return {
			}
		},
		computed: {

		},
		mounted() {
			
		},
		methods: {
			rasetTable () {
				this.table.where.version_code = ''
				this.inputChange()
			},
			// 选择
			inputChange() {
				this.$emit('update:table', this.table)
				this.$emit('setInfo')
			},
		}
	}
</script>

<style lang="less" scoped>

</style>
