<template>
	<ele-div>
		<div style="font-size: 24px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			line-height: 22px;padding-left: 30px;padding-top: 30px;">资料维护</div>
		<div style="padding: 70px 100px;">
			<el-form class="le-matter" label-width="80px" style="width: 500px;" ref="form" :model="form" :rules="rules"
				@submit.native.prevent>
				<el-form-item prop="corp_name">
					<span slot="label" class="le-form-item__label">机构名称</span>
					<el-input v-model="form.corp_name" placeholder="请输入机构名称"></el-input>
				</el-form-item>
				<el-form-item prop="province">
					<span slot="label" class="le-form-item__label">所属地区</span>
					<area-select @getAara="getAara" :key="areaValue3.length" :area="areaValue3"></area-select>
				</el-form-item>
				<el-form-item prop="address">
					<span slot="label" class="le-form-item__label">详细地址</span>
				 <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
				</el-form-item>
				<el-form-item prop="real_name">
					<span slot="label" class="le-form-item__label">联系人</span>
					<el-input v-model="form.real_name" placeholder="请输入联系人"></el-input>
				</el-form-item>
				<el-form-item prop="contact">
					<span slot="label" class="le-form-item__label">联系方式</span>
					<el-input v-model="form.contact" placeholder="请输入联系方式"></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label" class="le-form-item__label"></span>
					<el-button type="primary" @click="affirm">保存设置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</ele-div>
</template>

<script>
	import AreaSelect from "@/components/City1/index.vue";
	export default {
		data() {
			return {
				form: {
					corp_name: '',
					address: '',
					real_name: '',
					contact: '',
					province: '',
					city: '',
					district: '',
				},
				rules: {
					corp_name: [{
						required: true,
						message: '请输入机构名称',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					contact: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}, {
						pattern: /^((0\d{2,3}-\d{7,8})|(1[3657894]\d{9}))$/,
						message: "请输入合法手机号/电话号",
						trigger: "blur"
					}],
					province: [{
						required: true,
						message: '请选择地区',
						trigger: 'change'
					}]
				},
				areaValue3: []
			};
		},
		components: {
			AreaSelect
		},
		methods: {
			affirm: function(e) {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.corp.edit/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: '编辑信息成功',
									type: 'success'
								});
							}
						})
					} else {
						return false;
					}
				});
			},
			getAara(data) {
				this.form.province = data[0]
				this.form.city = data[1]
				this.form.district = data[2]
			}
		},
		mounted() {
			this.$http.request({
				url: '/api.admin.corp.mydetail/',
				method: 'post',
				data: {},
			}).then((res) => {
				this.form = {
					corp_name: res.data.corp_name,
					address: res.data.address,
					real_name: res.data.real_name,
					contact: res.data.contact,
					province: res.data.province,
					city: res.data.city,
					district: res.data.district,
				}
				this.areaValue3 = [this.form.province, this.form.city, this.form.district]
			})
		}
	};
</script>

<style>
</style>
