<template>
	<ele-div style="position: relative;">
		<div>
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
			<div style="margin-left: 20px;padding-top: 20px;position: absolute;right: 20px;top: 0px;">
				<el-button module="admins" width="460" title="添加管理员" action="getFilterSearch" v-popup.adminAdd type="primary" icon="el-icon-circle-plus-outline">添加</el-button>
			</div>
		</div>
		<div style="display: flex; margin-top: -20px;" >
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :choose.sync="choose" :key="tableKey" :config="table">
					<template slot="table">
						<el-table-column prop="corp_name" label="机构名称">
						</el-table-column>
						<el-table-column prop="admin_name" label="账号">
						</el-table-column>
						<el-table-column prop="role_name" label="角色名称">
						</el-table-column>
						<el-table-column prop="logins" label="登录次数">
						</el-table-column>
						<el-table-column prop="last_login_time" label="最后登录时间" width="180">
						</el-table-column>
						<el-table-column prop="last_ip" label="最后登录IP">
						</el-table-column>
						<el-table-column prop="is_lock" label="状态">
							<template slot-scope="scope, index">
								<span v-if="scope.row.is_lock == '正常'" style="color: #67c23a;">正常</span>
								<span v-if="scope.row.is_lock == '锁定'" style="color: #e6a23c;">锁定</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" fixed="right" min-width="270">
							<template slot-scope="scope, index">
								<div class="btns-rad100">
									<el-button :key="'gly2edit' + scope.row.idKey" :id="'gly2edit' + scope.row.idKey"  module="admins" width="460" title="编辑管理员信息" size="small" class="el-button-radio" action="getFilterSearch" v-popup.adminAdd="{...scope.row, 'editype': 'info'}" type="text" >编辑</el-button>
									<el-button :key="'gly1edit' + scope.row.idKey" :id="'gly1edit' + scope.row.idKey"  module="admins" width="460" title="重置密码" size="small" class="el-button-radio" action="getFilterSearch" v-popup.adminAdd="{...scope.row, 'editype': 'password'}" type="text">重置密码</el-button>
									<ele-pop-confirm width="424px" @confirm="getFilterSearch" :key="scope.row.admin_id"
										:httpData="{'url': '/api.admin.admin.del/', 'data': {'admin_id': scope.row.admin_id}}" isType="deletePost"
										title="管理员将被删除且无法撤销，确定删除？">
										<el-button style="margin-left: 6px;color: #FE0000 !important;" class="el-button-radio" type="text"
											size="small">删除</el-button>
									</ele-pop-confirm>
								</div>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.admin.list/",
					method: "post",
					where: {
						admin_name: '',//	是	string	账号名称
						mobile: '',//	是	string	联系方式
						role_id: null,//	是	int	所属角色
						corp_id: null,//	是	int	所属机构
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false // 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				// 表格多选
				choose: [],
				tableKey: Math.random()
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.tableKey = Math.random()
				this.$refs.table.reload()
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>
