<template>
	<div style="position: relative;" class="formbanmo">
		<div style="padding: 22px 0 0px 20px;background-color: #fff;">
			<div class="filter-data">
				<div class="filter-data-item">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="交易单号">
							<el-input v-model="table.where.trade_no" clearable placeholder="输入交易单号查询"></el-input>
						</el-form-item>
						<el-form-item label="手机号码">
							<el-input v-model="table.where.mobile" clearable placeholder="输入手机号码查询"></el-input>
						</el-form-item>
						<el-form-item label="支付日期">
							<el-date-picker v-model="table.where.start_date" format="yyyy-MM-dd"  value-format="yyyy-MM-dd" type="date" placeholder="开始日期"> 
							</el-date-picker>
							<span style="width: 20px;display: inline-block;"></span>
							<el-date-picker v-model="table.where.end_date" format="yyyy-MM-dd"  value-format="yyyy-MM-dd" type="date" placeholder="截止日期" >
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" icon="el-icon-search" @click="inputChange">查询</el-button>
							<el-button type="primary" icon="el-icon-refresh" @click="rasetTable">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	export default {
		name: "filter-type",
		props: ['table'],
		data() {
			return {

			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {
			rasetTable () {
				this.table.where.trade_no = ''
				this.table.where.mobile = ''
				this.table.where.start_date = ''
				this.table.where.end_date = ''
				this.inputChange()
			},
			// 选择
			inputChange() {
				this.table.where.page = 1
				this.$emit('update:table', this.table)
				this.$emit('setInfo')
			},
		}
	}
</script>

<style lang="less" scoped>

</style>
