<template>
	<div style="position: relative;">
		<div style="padding: 22px 0 0 20px;">
			<div class="filter-data">
				<div class="filter-data-item">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="账号名称">
							<el-input v-model="table.where.admin_name" clearable placeholder="输入账号名称查询"></el-input>
						</el-form-item>
						<el-form-item label="联系方式">
							<el-input v-model="table.where.mobile" clearable placeholder="输入联系方式查询"></el-input>
						</el-form-item>
						<el-form-item label="所属角色">
							<el-select v-model="table.where.role_id" placeholder="请选择所属角色" style="width: 100%;">
								<el-option v-for="item in roleList" :key="item.value" :label="item.role_name" :value="item.role_id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所属机构">
							<el-select v-model="table.where.corp_id" placeholder="请选择所属机构" style="width: 100%;">
								<el-option v-for="item in corpList" :key="item.value" :label="item.corp_name" :value="item.corp_id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" icon="el-icon-search" @click="inputChange">查询</el-button>
							<el-button type="primary" icon="el-icon-refresh" @click="rasetTable">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	export default {
		name: "filter-type",
		props: ['table'],
		data() {
			return {
				corpList: [],
				roleList: []
			}
		},
		computed: {

		},
		mounted() {
			this.getCorpList()
			this.getRoleList()
		},
		methods: {
			rasetTable () {
				this.table.where.admin_name = ''
				this.table.where.mobile = ''
				this.table.where.role_id = null
				this.table.where.corp_id = null
				this.inputChange()
			},
			// 选择
			inputChange() {
				this.table.where.page = 1
				this.$emit('update:table', this.table)
				this.$emit('setInfo')
			},
			getCorpList () {
				this.$http.request({
					url: '/api.admin.corp.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.corpList = res.data
				})
			},
			getRoleList () {
				this.$http.request({
					url: '/api.admin.role.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.roleList = res.data
				})
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
