<template>
	<div>
		<div>
			<div class="title-h2">
				<span>经营概括</span>
			</div>
			<div class="totl">
				<ele-div v-for="(item,index) in list" class="totl-item">
					<div class="left">
						<p>{{item.name}}</p>
						<div style="font-size: 24px;font-weight: 400;margin-top: 8px;margin-left: -4px;"><span
								v-if="item.decimals == 0" style="margin-left: 7px;"></span>
							{{item.decimals == 0 ? '' : '￥'}}
							<countTo style="margin-left: -6px;" :startVal='0' :endVal='item.startVal'
								:decimals="item.decimals == 0 ? item.decimals : 2" :duration='3000'></countTo>
						</div>
					</div>
					<div class="right">
						<img :src="item.img" alt="">
					</div>
				</ele-div>
			</div>
		</div>
		<div class="ddow">
			<div>
				<div class="title-h2">
					<span>近一年实时数据</span>
				</div>
				<ele-div style="height:350px">
					<ele-chart :option="pieChartOption" style="height: 350px" />
				</ele-div>
			</div>
			<div>
				<div class="title-h2">
					<span>终端金额排名Top10</span>
				</div>
				<ele-div style="height:350px">
					<ele-chart :option="barOption" style="height: 350px" />
				</ele-div>
			</div>
		</div>
		<div class="ddow">
			<div>
				<div class="title-h2">
					<span>近一年支付渠道分布情况</span>
				</div>
				<ele-div style="height:350px">
					<ele-chart :option="bar1Option" style="height: 350px" />
				</ele-div>
			</div>
			<div>
				<div class="title-h2">
					<span>支付渠道分布比例</span>
				</div>
				<ele-div style="height:350px">
					<ele-chart :option="pine2Option" style="height: 350px" />
				</ele-div>
			</div>
		</div>
	</div>
</template>

<script>
	import EleChart from "@/components/EleChart";
	import echarts from "echarts";
	import countTo from 'vue-count-to';
	export default {
		data() {
			return {
				list: [{
					name: '今日捐款',
					key: 'today_amount',
					startVal: 0,
					img: require('../../../assets/im/j1.png')
				}, {
					name: '昨日捐款',
					key: 'yesterday_amount',
					startVal: 0,
					img: require('../../../assets/im/j2.png')
				}, {
					name: '本周捐款',
					key: 'week_amount',
					startVal: 0,
					img: require('../../../assets/im/j3.png')
				}, {
					name: '本月捐款',
					key: 'month_amount',
					startVal: 0,
					img: require('../../../assets/im/j4.png')
				}, {
					name: '累计微信捐款',
					key: 'total_wx_amount',
					startVal: 0,
					img: require('../../../assets/im/j5.png')
				}, {
					name: '累计支付宝捐款',
					key: 'total_ali_amount',
					startVal: 0,
					img: require('../../../assets/im/j6.png')
				}, {
					name: '累计捐款',
					key: 'total_amount',
					startVal: 0,
					img: require('../../../assets/im/j7.png')
				}, {
					name: '终端总数',
					key: 'total_device',
					startVal: 0,
					decimals: 0,
					img: require('../../../assets/im/j8.png')
				}],
				pageData: {
					device: [],
					line_amount_count: [],
					pay_type: [],
					line_amount_display: []
				},

			}
		},
		components: {
			EleChart,
			countTo
		},
		mounted() {
			this.getList()
		},
		computed: {
			pine2Option() {
				let datas = this.pageData.pay_type.map((v) => {
					return {
						"value": v.amount,
						"name": v.name,
					}
				})
			
				return {
					color: ["#ADACFD", "#3EB0FD", "#F09615", "#0079E6"],
					tooltip: {
						trigger: "item",
						formatter: "{d}%",
					},
					series: [{
						name: "",
						type: "pie",
						radius: ["0%", "60%"],
						center: ["50%", "50%"],
						label: {
							formatter: "{d}%",
						},
						labelLine: {
							length: 25,
							length2: 20,
						},
						data: datas,
						label: {
							color: "rgba(255,255,255,.45)",
							fontSize: 14,
							formatter: function (data) {
								return  `{a|${data.data.value + '元'}}` +'\n'+ `{b|${data.data.name}}`
							},
							rich: {
								a: {
									fontSize: 20,
									lineHeight: 30
								},
								b: {
									fontSize: 14,
									color: '#333333',
									lineHeight: 20
								},
							}
						}
					}, ],
				};
			},
			pine1Option() {
				let datas = this.pageData.pay_type.map((v) => {
					return {
						"value": v.amount,
						"name": v.name,
					}
				})

				return {
					color: ["#FF635F", "#70A0FF", "#F09615", "#0079E6"],
					legend: {
						itemHeight: 14,
						itemWidth: 14,
						icon: "rect",
						orient: "vertical",
						top: "center",
						right: "6%",
						textStyle: {
							align: "left",
							color: "#",
							verticalAlign: "middle",
							rich: {
								name: {
									width: 90,
									fontSize: 16,
								},
								value: {
									align: "right",
									fontFamily: "Medium",
									fontSize: 16,
								},
								rate: {
									width: 10,
									align: "right",
									fontSize: 16,
								},
							},
						},
						data: datas,
						formatter: (name) => {
							if (datas.length) {
								const item = datas.filter((item) => item.name === name)[0];
								return `{name|${name}}{value| ${item.value}}元`;
							}
						},
					},
					tooltip: {
						trigger: "item",
						formatter: "{d}%",
					},
					series: [{
						name: "",
						type: "pie",
						radius: ["40%", "60%"],
						center: ["35%", "50%"],
						roseType: "radius",
						label: {
							formatter: "{d}%",
						},
						labelLine: {
							length: 25,
							length2: 20,
						},
						data: datas,
						label: {
							color: "rgba(255,255,255,.45)",
							fontSize: 14,
							formatter: function (data) {
								return  `{a|${data.data.value + '元'}}` +'\n'+ `{b|${data.data.name}}`
							},
							rich: {
								a: {
									fontSize: 20,
									lineHeight: 30
								},
								b: {
									fontSize: 14,
									color: '#333333',
									lineHeight: 20
								},
							}
						}
					}, ],
				};
			},
			barOption() {
				// let name = this.pageData.device.map((v) => {
				// 	return v.name
				// })
				let data = this.pageData.device.map((v) => {
					return {
						name: v.name,
						value: v.amount
					}
				})
				console.log(data, 'datadata')
				
				// return {
				//     tooltip: {
				//         trigger: 'axis',
				//         axisPointer: {
				//             type: 'shadow'
				//         }
				//     },
				//     grid: {
				//         left: '3%',
				//         right: '4%',
				//         bottom: '8%',
				//         containLabel: true
				//     },
				//     yAxis: {
				//         type: 'value',
				        
				//         splitLine: {
				//             show: true,
				//             lineStyle: {
				//               color: '#f3f3f3'
				//             }
				//           },
				//           axisLine: {
				//               show: false,
				//             lineStyle: {
				//               color: '#D2E6F9'
				//             }
				//           },
				//           axisTick: {
				//             show: false
				//           },
				//           axisLabel: {
				//             color: '#000'
				//           }
				//     },
				//     xAxis: {
				//         type: 'category',
				//         data: name,
				//         axisLine: {
				//             show: false,
				//           },
				//           splitLine: {
				//               show: false
				//           },
				//           axisTick: {
				//             show: false
				//           },
				//           axisLabel: {
				//             color: '#000'
				//           }
				//     },
				//     series: [
				//         {
				//             name: '已上报',
				//             type: 'bar',
				//             barWidth: 24,
				//             barGap: '40%',
				//             data: value,
				//             itemStyle: {
				//                 color: '#688aff'
				//             }
				//         }
				//     ]
				// };
				
				// 基于准备好的dom，初始化echarts实例
				// 绘制图表
				let self = this;
				var colorList = [
					"#73DDFF",
					"#73ACFF",
					"#FDD56A",
					"#FDB36A",
					"#FD866A",
					"#9E87FF",
					"#58D5FF",
				];
				
				
				var total = 0
				data.forEach(v => {
					total += parseInt(v.value)
				})
				
				function Percentage(num) { 
				    if (num == 0 || total == 0){
				        return 0;
				    }
				    return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
				}
				
				return {
					// title: {
					//     text: '{header1| 名称与占比}' +
					//         '{header2| 数量}' ,
					//     textAlign: 'left',
					//     left: '49%',
					//     top: '30%',
					//     textStyle: {
					//         color: '#ccc',
					//         rich: {
					//             header1: {
					//                 width: 130,
					//                 fontSize: 15
					//             },
					//             header2: {
					//                 width: 85,
					//                 fontSize: 15
					//             },
					//             header3: {
					//                 fontSize: 15
					//             }
					//         }
					//     }
					// },
					title: [],
					legend: {
						// selectedMode: false, // 取消图例上的点击事件
						type: "plain",
						icon: "circle",
						orient: "vertical",
						left: "49%",
						top: "center",
						align: "left",
						itemGap: 15,
						itemWidth: 10, // 设置宽度
						itemHeight: 10, // 设置高度
						symbolKeepAspect: false,
						textStyle: {
							color: "#000",
							rich: {
								name: {
									verticalAlign: "right",
									align: "left",
									width: 120,
									fontSize: 12,
								},
								value: {
									align: "left",
									width: 60,
									fontSize: 12,
								},
								count: {
									align: "left",
									width: 80,
									fontSize: 12,
								},
								upRate: {
									align: "left",
									fontSize: 12,
									color: "#00cf90",
								},
								downRate: {
									align: "left",
									fontSize: 12,
									color: "#ff5722",
								},
							},
						},
						data: data.map((item) => item.name),
						formatter: function(name) {
							let className = "upRate";
							let rateIcon = "▲";
							if (data && data.length) {
								for (var i = 0; i < data.length; i++) {
									if (name === data[i].name) {
										if (data[i].rate < 0) {
											className = "downRate";
											rateIcon = "▼";
										}
										return (
											"{name| " +
											name +
											"}   " +
											"{count| " +
											data[i].value +
											"} "
										);
									}
								}
							}
						},
					},
					series: [{
						name: "数量",
						type: "pie",
						radius: ["40%", "55%"],
						center: ["25%", "55%"],
						data: data,
						label: {
							normal: {
								show: false,
								position: "center",
								formatter: "{text|{c}}\n{b}",
								rich: {
									text: {
										align: "center",
										verticalAlign: "middle",
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: "center",
										verticalAlign: "middle",
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: "12",
								},
							},
						},
						itemStyle: {
							normal: {
								color: function(params) {
									return colorList[params.dataIndex];
								},
							},
						},
						labelLine: {
							normal: {
								show: true,
							},
						},
					}, ],
				};
			},
			bar1Option() {
				let name = this.pageData.line_amount_display.map((v) => {
					return v.name
				})
				let value = this.pageData.line_amount_display.map((v) => {
					return v.amount
				})
				let value1 = this.pageData.line_amount_display.map((v) => {
					return v.wx_amount
				})
				let value2 = this.pageData.line_amount_display.map((v) => {
					return v.ali_amount
				})
				
				let bgColor = "#fff";
				let color = [
					"#FFC005",
					"#8B5CFF",
					"#00CA69",	
					"#3473FF",
					"#4AD7BB",
					
					"#FF515A",
					
					
				];
				// [233, 233, 200, 180, 199, 233, 210, 180]
				const hexToRgba = (hex, opacity) => {
					let rgbaColor = "";
					let reg = /^#[\da-f]{6}$/i;
					if (reg.test(hex)) {
						rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
				      "0x" + hex.slice(3, 5)
				    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
					}
					return rgbaColor;
				}
				
				return {
					backgroundColor: bgColor,
					color: color,
					legend: {
						right: 10,
						top: 10
					},
					tooltip: {
						trigger: "axis",
						formatter: function(params) {
							let html = '';
							params.forEach(v => {
								console.log(v)
								html += `<div style="color: #666;font-size: 14px;line-height: 24px">
				                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
				                 ${v.seriesName}: ${v.name}
				                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px">${v.value}</span>`;
							})
				
				
							return html
						},
						extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
					},
					grid: {
						left: '3%',
						right: '3%',
						top: '12%',
						bottom: '5%',
						containLabel: true
					},
					xAxis: [{
						type: "category",
						boundaryGap: false,
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: "#333"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#D9D9D9"
							}
						},
						data: name
					}],
					yAxis: [{
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#666"
							}
						},
						nameTextStyle: {
							color: "#666",
							fontSize: 12,
							lineHeight: 40
						},
						splitLine: {
							lineStyle: {
								type: "dashed",
								color: "#E9E9E9"
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}, {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#666"
							}
						},
						nameTextStyle: {
							color: "#666",
							fontSize: 12,
							lineHeight: 40
						},
						splitLine: {
							lineStyle: {
								type: "dashed",
								color: "#E9E9E9"
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}],
					series: [{
						name: "总金额",
						type: "line",
						smooth: true,
						// showSymbol: false,/
						symbolSize: 0,
						zlevel: 3,
						lineStyle: {
							normal: {
								color: color[0],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[0], 0.5),
								shadowOffsetY: 8
							}
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[0], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[0], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[0], 0.1),
								shadowBlur: 10
							}
						},
						data: value
					}, {
						name: "微信金额",
						type: "line",
						yAxisIndex: 1,
						smooth: true,
						// showSymbol: false,
						symbolSize: 0,
						zlevel: 3,
						lineStyle: {
							normal: {
								color: color[1],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[1], 0.5),
								shadowOffsetY: 8
							}
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[1], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[1], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[1], 0.1),
								shadowBlur: 10
							}
						},
						data: value1
					}, {
						name: "支付宝金额",
						type: "line",
						yAxisIndex: 1,
						smooth: true,
						// showSymbol: false,
						symbolSize: 0,
						zlevel: 3,
						lineStyle: {
							normal: {
								color: color[1],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[1], 0.5),
								shadowOffsetY: 8
							}
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[1], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[1], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[1], 0.1),
								shadowBlur: 10
							}
						},
						data: value2
					}]
				};
			},
			// 访问饼图配置
			pieChartOption() {
				let bgColor = "#fff";
				let color = [
					"#3473FF",
					"#4AD7BB",
					"#FFC005",
					"#FF515A",
					"#8B5CFF",
					"#00CA69"
				];
				let echartData = this.pageData.line_amount_count

				let xAxisData = echartData.map(v => v.name);
				//  ["1", "2", "3", "4", "5", "6", "7", "8"]
				let yAxisData1 = echartData.map(v => v.amount);
				let yAxisData2 = echartData.map(v => v.count);
				// [233, 233, 200, 180, 199, 233, 210, 180]
				const hexToRgba = (hex, opacity) => {
					let rgbaColor = "";
					let reg = /^#[\da-f]{6}$/i;
					if (reg.test(hex)) {
						rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
				      "0x" + hex.slice(3, 5)
				    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
					}
					return rgbaColor;
				}

				return {
					backgroundColor: bgColor,
					color: color,
					legend: {
						right: 10,
						top: 10
					},
					tooltip: {
						trigger: "axis",
						formatter: function(params) {
							let html = '';
							params.forEach(v => {
								console.log(v)
								html += `<div style="color: #666;font-size: 14px;line-height: 24px">
				                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
				                 ${v.seriesName}: ${v.name}
				                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px">${v.value}</span>`;
							})


							return html
						},
						extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
					},
					grid: {
						left: '3%',
						right: '3%',
						top: '12%',
						bottom: '5%',
						containLabel: true
					},
					xAxis: [{
						type: "category",
						boundaryGap: false,
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: "#333"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#D9D9D9"
							}
						},
						data: xAxisData
					}],
					yAxis: [{
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#666"
							}
						},
						nameTextStyle: {
							color: "#666",
							fontSize: 12,
							lineHeight: 40
						},
						splitLine: {
							lineStyle: {
								type: "dashed",
								color: "#E9E9E9"
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}, {
						type: "value",
						axisLabel: {
							textStyle: {
								color: "#666"
							}
						},
						nameTextStyle: {
							color: "#666",
							fontSize: 12,
							lineHeight: 40
						},
						splitLine: {
							lineStyle: {
								type: "dashed",
								color: "#E9E9E9"
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}],
					series: [{
						name: "金额",
						type: "line",
						smooth: true,
						// showSymbol: false,/
						symbolSize: 0,
						zlevel: 3,
						lineStyle: {
							normal: {
								color: color[0],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[0], 0.5),
								shadowOffsetY: 8
							}
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[0], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[0], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[0], 0.1),
								shadowBlur: 10
							}
						},
						data: yAxisData1
					}, {
						name: "笔数",
						type: "line",
						yAxisIndex: 1,
						smooth: true,
						// showSymbol: false,
						symbolSize: 0,
						zlevel: 3,
						lineStyle: {
							normal: {
								color: color[1],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[1], 0.5),
								shadowOffsetY: 8
							}
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[1], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[1], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[1], 0.1),
								shadowBlur: 10
							}
						},
						data: yAxisData2
					}]
				};
			},
		},
		methods: {
			getList() {
				this.$http.request({
					url: '/api.admin.work.data/',
					method: 'post',
					data: {}
				}).then((res) => {
					if (res.code == 200) {
						console.log(res.data, 'res.datares.data')
						this.pageData = res.data
						this.list.forEach((v) => {
							for (var key in res.data) {
								if (v.key == key) {
									v.startVal = res.data[key]
								}
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.ddow {
		display: flex;

		&>div {
			&:nth-child(1) {
				width: calc(65% - 20px);
				margin-right: 20px;
			}

			&:nth-child(2) {
				width: 35%;
			}
		}
	}

	.title-h2 {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		&>span {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			line-height: 20px;
		}
	}
	.ddow{
		margin-top: 20px;
	}
	.totl {
		display: flex;
		flex-wrap: wrap;

		.totl-item {
			padding: 0 30px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: calc(25% - 15px);
			height: 90px;
			margin-right: 20px;
			margin-bottom: 10px;

			&:nth-child(4n) {
				margin-right: 0px;
			}

			.left {
				p {
					opacity: 0.6;
					margin-bottom: 8px;
				}
			}

			.right {
				img {
					width: 55px;
					height: 55px;
					display: block;
				}
			}
		}
	}
</style>
