<template>
	<ele-div>
		<div style="font-size: 24px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			line-height: 22px;padding-left: 30px;padding-top: 30px;">参数配置</div>
		<div style="padding: 70px 100px;">
			<el-form class="le-matter" label-width="120px" style="width: 500px;" ref="form" :model="form" :rules="rules" @submit.native.prevent>
				<el-form-item prop="sys_name">
					<span slot="label" class="le-form-item__label">系统名称</span>
					<el-input v-model="form.sys_name" placeholder="请输入系统名称"></el-input>
				</el-form-item>
				<el-form-item prop="api_web_url">
					<span slot="label" class="le-form-item__label">接口地址</span>
					<el-input v-model="form.api_web_url" placeholder="请输入接口地址"></el-input>
				</el-form-item>
				<el-form-item prop="file_web_url">
					<span slot="label" class="le-form-item__label">路径地址</span>
					<el-input v-model="form.file_web_url" placeholder="请输入路径地址"></el-input>
				</el-form-item>
				<el-form-item prop="bucket">
					<span slot="label" class="le-form-item__label">OSS-bucket</span>
					<el-input v-model="form.bucket" placeholder="请输入bucket"></el-input>
				</el-form-item>
				<el-form-item prop="endpoint">
					<span slot="label" class="le-form-item__label">OSS-endpoint</span>
					<el-input v-model="form.endpoint" placeholder="请输入endpoint"></el-input>
				</el-form-item>
				<el-form-item prop="keyid">
					<span slot="label" class="le-form-item__label">OSS-keyid</span>
					<el-input v-model="form.keyid" placeholder="请输入keyid"></el-input>
				</el-form-item>
				<el-form-item prop="keysecret">
					<span slot="label" class="le-form-item__label">OSS-keysecret</span>
					<el-input v-model="form.keysecret" placeholder="请输入keysecret"></el-input>
				</el-form-item>
				<el-form-item >
					<span slot="label" class="le-form-item__label"></span>
					<el-button type="primary" @click="setConfig">保存设置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</ele-div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					sys_name: '',//	string	系统名称
					api_web_url: '',//	string	接口地址
					file_web_url: '',//	string	路径地址
					"bucket": "",
					"endpoint": "",
					"keyid": "",
					"keysecret": ""
				},
				rules: {
					sys_name: [{
						required: true,
						message: '请输入系统名称',
						trigger: 'blur'
					}],
					api_web_url: [{
						required: true,
						message: '请输入接口地址',
						trigger: 'blur'
					}],
					file_web_url: [{
						required: true,
						message: '请输入路径地址',
						trigger: 'blur'
					}],
					bucket: [{
						required: true,
						message: '请输入bucket',
						trigger: 'blur'
					}],
					endpoint: [{
						required: true,
						message: '请输入endpoint',
						trigger: 'blur'
					}],
					keyid: [{
						required: true,
						message: '请输入keyid',
						trigger: 'blur'
					}],
					keysecret: [{
						required: true,
						message: '请输入keysecret',
						trigger: 'blur'
					}],
				},
			};
		},
		methods: {
			getConfig () {
				this.$http.request({
					url: '/api.admin.config.list/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.form = res.data
				})
			},
			setConfig () {
				this.$http.request({
					url: '/api.admin.config.add/',
					method: 'post',
					data: {
						config: JSON.stringify(this.form)
					},
				}).then((res) => {
					this.$message({
						message: '保存设置成功',
						type: 'success'
					});
				})
			}
		},
		mounted() {
			this.getConfig()
		}
	};
</script>

<style>
</style>
