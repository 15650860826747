<template>
	<div style="position: relative;">
		<div style="padding: 22px 0 0 20px;">
			<div class="filter-data">
				<div class="filter-data-item">
					<el-form :inline="true" class="demo-form-inline">
					  <el-form-item label="机构名称">
					    <el-input v-model="table.where.corp_name" clearable placeholder="输入机构名称查询"></el-input>
					  </el-form-item>
					  <el-form-item label="联系人">
					    <el-input v-model="table.where.real_name" clearable placeholder="输入联系人查询"></el-input>
					  </el-form-item>
					  <el-form-item label="联系方式">
					    <el-input v-model="table.where.contact" clearable placeholder="输入联系方式查询"></el-input>
					  </el-form-item>
					 <el-form-item>
					 	<el-button type="primary" icon="el-icon-search" @click="inputChange">查询</el-button>
					 	<el-button type="primary" icon="el-icon-refresh" @click="rasetTable">重置</el-button>
					 </el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	export default {
		name: "filter-type",
		props: ['table'],
		data() {
			return {
				
			}
		},
		computed: {
			
		},
		mounted () {
			
		},
		methods: {
			rasetTable () {
				this.table.where.corp_name = ''
				this.table.where.real_name = ''
				this.table.where.contact = ''
				this.inputChange()
			},
			// 选择
			inputChange () {
				this.table.where.page = 1
				this.$emit('update:table', this.table)
				this.$emit('setInfo')
			},
		}
	}
</script>

<style lang="less" scoped>
	
</style>
